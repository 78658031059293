/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
  ==========================================================================================*/

export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "dashboard",
    icon: "HomeIcon",
  },
  {
    name: "Campaigns",
    slug: "campaigns",
    icon: "BarChart2Icon",
    url: "/campaigns",
    submenu: [
      {
        url: "/campaigns",
        name: "Overview",
        slug: "campaigns",
        icon: "FileIcon",
      },
      {
        url: "/new-campaign",
        slug: "new-campaign",
        name: "New Campaign",
        i18n: "New Campaign",
      },
      {
        url: "/notifications",
        slug: "notifications",
        name: "Notifications",
        i18n: "Notifications",
      },
      {
        url: "/rules",
        slug: "rules",
        name: "Rules",
        i18n: "Rules",
      },
      {
        url: "/settings/taboola",
        name: "Taboola",
        slug: "taboola",
        submenu: [
          {
            url: "/settings/taboola/globalSiteBlacklist",
            name: "Global Site Blacklist",
            slug: "globalSiteBlacklist",
            icon: "FileIcon",
          },
          {
            url: "/settings/taboola/siteBlacklistPresets",
            name: "Site Blacklist Presets",
            slug: "siteBlacklistPresets",
            icon: "FileIcon",
          },
        ],
      },
      {
        url: "/settings/snapchat",
        name: "Snapchat",
        slug: "snapchat",
        submenu: [
          {
            url: "/settings/snapchat/tokenReset",
            name: "Token Reset",
            slug: "tokenReset",
          },
        ],
      },
    ],
  },
  {
    name: "Articles",
    slug: "articles",
    icon: "BookOpenIcon",
    url: "/articles",
    submenu: [
      {
        url: "/articles",
        name: "Overview",
        slug: "articles",
        icon: "BookOpenIcon",
      },
    ],
  },
  {
    slug: "monetization",
    name: "Monetization",
    icon: "DollarSignIcon",
    submenu: [
      {
        url: "/monetization-presets",
        slug: "monetization-presets",
        name: "Presets",
        i18n: "Presets",
      },
      {
        url: "/new-monetization-preset",
        slug: "new-monetization-preset",
        name: "New Preset",
        i18n: "New Preset",
      },
      {
        url: "/settings/prebid/adUnits",
        name: "Ad Units",
        slug: "ad-units",
      },
      {
        url: "/settings/prebid/ssps",
        name: "SSP Globals",
        slug: "ssps",
      },
    ],
  },
  {
    name: "Creative Library",
    slug: "library",
    icon: "StarIcon",
    submenu: [
      {
        url: "/library",
        name: "Overview",
        slug: "library",
      },
      {
        url: "/new-version",
        name: "New Version",
        slug: "new-version",
      },
    ],
  },
  {
    name: "Reports",
    slug: "reports",
    icon: "FileTextIcon",
    url: "/reports",
    submenu: [
      {
        url: "/bi",
        slug: "bi",
        name: "BI",
      },
      {
        url: "/os-and-browser",
        slug: "os-and-browser",
        name: "OS & Browser",
        i18n: "OS & Browser",
      },
      {
        url: "/budget-reached",
        slug: "budget-reached",
        name: "Budget Reached",
        i18n: "Budget Reached",
      },
    ],
  },
  {
    slug: "push",
    name: "Push",
    icon: "BellIcon",
    submenu: [
      {
        url: "/push/overview",
        name: "Overview",
        slug: "push-overview",
        icon: "",
      },
      {
        url: "/push/new",
        name: "Send",
        slug: "push-new",
        icon: "",
      },
      {
        url: "/push/audiences",
        name: "Audiences",
        slug: "push-audiences",
        icon: "",
      },
      {
        url: "/push/audiences/new",
        name: "Create Audience",
        slug: "push-audiences-new",
        icon: "",
      },
    ],
  },
  {
    url: "/activity-log",
    slug: "activity-log",
    name: "Activity Log",
    i18n: "Activity Log",
    icon: "ListIcon",
  },
  {
    slug: "admin",
    name: "Admin",
    icon: "SettingsIcon",
    submenu: [
      {
        url: "/admin/publishers",
        name: "Publishers",
        slug: "publishers",
        icon: "",
      },
      {
        url: "/admin/users",
        name: "Users",
        slug: "users",
        icon: "",
      },
    ],
  },
];

<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.first_name">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold m-0">{{ activeUserInfo.first_name }}</p>      
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

    <div class="con-img ml-3">
      <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
    </div>

    <vs-dropdown-menu class="vx-navbar-dropdown">
      <ul style="min-width: 9rem">                    
        <li
        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
        @click="logout">
          <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
          <span class="ml-2">Logout</span>
        </li>
      </ul>
    </vs-dropdown-menu>
  </vs-dropdown>
</div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser
      }
    },
    methods: {
      logout() {
      localStorage.removeItem('token');
      this.$router.push('/login').catch(() => {})
    },
    navToSettings() {
       this.$router.push('/settings')
    }
  }
}
</script>
